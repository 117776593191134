import { IAnalyticTab } from '@aca-new/app/pages/analytics/shared/models/interfaces/analytics.interface';
import { AnalyticsHttpService } from '@aca-new/app/pages/analytics/shared/services/analytics.http.service';
import { IAsideMore } from '@aca-new/app/pages/main/shared/components/aside/shared/components/aside-more/shared/models/interfaces/aside-more.interface';
import { MENU_ITEMS_COMMON, MENU_ITEMS_LIFE_SCIENCE } from '@aca-new/app/pages/main/shared/components/aside/shared/constants/aside-menus.constants';
import { IAsideItem } from '@aca-new/app/pages/main/shared/components/aside/shared/models/interfaces/aside-item.interface';
import { IDynamicBookingMenus } from '@aca-new/app/pages/main/shared/components/aside/shared/models/interfaces/dynamic-booking-menus.interface';
import { AsideHttpService } from '@aca-new/app/pages/main/shared/components/aside/shared/services/aside.http.service';
import { AsideService } from '@aca-new/app/pages/main/shared/components/aside/shared/services/aside.service';
import { IMyWindow } from '@aca-new/app/shared/interfaces/my-window.interface';
import { EStorageKeys } from '@aca-new/app/shared/models/enums/storage-keys.enum';
import { AppLocationService } from '@aca-new/app/shared/services/browser-services/app-location/app-location-service';
import { QimaIconService } from '@aca-new/app/shared/services/exported-services/qima-icon/qima-icon.service';
import { AcaMessageService } from '@aca-new/app/shared/services/post-message-services/aca-message/aca-message.service';
import { StorageService } from '@aca-new/app/shared/services/storage-services/storage.service';
import { GoogleAnalyticsService } from '@aca-new/app/shared/services/third-party-services/google-analytics/google-analytics.service';
import { AppAuthenticationService } from '@aca-new/app/shared/services/user-services/app-authentication/app-authentication.service';
import { AppUserSettingService } from '@aca-new/app/shared/services/user-services/app-user-setting/app-user-setting.service';
import { WINDOW } from '@aca-new/app/shared/tokens/window.token';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EQimaIconName, EQimaPosition, QimaResponsiveService } from '@qima/ngx-qima';
// eslint-disable-next-line @typescript-eslint/naming-convention
import LogRocket from 'logrocket';
import { Observable, tap } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-aside',
  templateUrl: './aside.component.html',
  styleUrls: ['./aside.component.scss'],
  animations: [
    trigger('expandCollapse', [state('expanded', style({ width: 240 })), state('collapsed', style({ width: 76 })), transition('* => *', [animate('200ms ease-in-out')])]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AsideComponent implements OnInit {
  /**
   * @description
   * Show or hide the aside menu of the app
   * @type {boolean}
   * @default false
   */
  public isNetworkOnly = this._appUserSettingService.getEnableUseNetwork() && !this._appUserSettingService.getEnableFoodCertification();
  public isSubMenuVisible: boolean = false;
  public isAsideMenuVisible: boolean = false;
  public isAsideMoreVisible: boolean = false;
  public asideItemCheckedIndex: number = -1;
  public subMenuItemCheckedIndex: number = -1;
  public isAsideCollapsed: boolean = false;
  public tooltipPosition: EQimaPosition = EQimaPosition.RIGHT;
  public isBookNowVisible: boolean = true;
  public isSystemSwitchVisible: boolean = !this._appUserSettingService.getIsQimaProduce() && !this._appUserSettingService.getIsLifeScience();
  public dynamicBookingMenus: IDynamicBookingMenus = {};
  public isMobile: boolean = false;
  public menuItems: IAsideItem[] = [];

  private readonly _isSuperMaster: boolean = this._appUserSettingService.getIsSuperMaster();

  public constructor(
    private readonly _appUserSettingService: AppUserSettingService,
    private readonly _appAuthenticationService: AppAuthenticationService,
    private readonly _appLocationService: AppLocationService,
    private readonly _googleAnalyticsService: GoogleAnalyticsService,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _asideService: AsideService,
    private readonly _router: Router,
    private readonly _storageService: StorageService,
    private readonly _asideHttpService: AsideHttpService,
    private readonly _analyticsHttpService: AnalyticsHttpService,
    private readonly _acaMessageService: AcaMessageService,
    private readonly _qimaResponsiveService: QimaResponsiveService,
    @Inject(WINDOW) private readonly _window: IMyWindow,
    public readonly qimaIcon: QimaIconService
  ) {}

  public ngOnInit(): void {
    this._initData();
    this._checkReportsLabel();
    this._watch();
  }

  public onAsideItemClick(index: Readonly<number>, item: IAsideItem, subMenuItems?: IAsideItem[]): void {
    if (item.id === 'bookNow') {
      // TODO deal with click book now
      return;
    }

    if (item.id === 'moreMenu') {
      this.isAsideMoreVisible = !this.isAsideMoreVisible;

      return;
    }

    // TODO deal with click book now and more menu

    if (item.subItems?.length) {
      this.isSubMenuVisible = true;
      this.subMenuItemCheckedIndex = index;
      this._asideService.updateAsideItemCheckedIndex(index);
    } else {
      this.isSubMenuVisible = false;

      if (subMenuItems) {
        index = -1;
        this._asideService.updateAsideItemCheckedIndex(index);
      }

      if (item.matchPath?.includes('/analytics/')) {
        this._asideService.updateIsAsideCollapsed(true);
      }

      this._asideService.updateAsideItemCheckedIndex(index);
      this._asideService.updateAsideMenuVisible(false);

      if (item.matchPath?.includes('/analytics/')) {
        this._asideService.updateIsAsideCollapsed(true);
      }

      if (!item.path) {
        return;
      }

      if (this._acaMessageService.isAcaFrameAvailable && this._acaMessageService.canAcaRouteTo(item.path)) {
        this._acaMessageService.informAcaPathChange(item.path);

        return;
      }

      void this._router.navigate([item.path]);
    }
  }

  public onBackToACAClick(): void {
    try {
      LogRocket.track('Switch to Classic View Clicked');

      if (!this._appLocationService.isQimaCn()) {
        this._googleAnalyticsService.sendEvent({
          event: 'switch_to_classic',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          event_category: 'User Interaction',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          event_label: 'Switch to Classic View Clicked',
        });
      }
    } catch (e) {
      console.error(e);
    }

    this._appLocationService.backToACA();
  }

  public onAsideMoreCloseClick(): void {
    this.isAsideMoreVisible = false;
  }

  public onAsideMoreMenuClick(data: IAsideMore): void {
    this.onAsideItemClick(data.index, data.item, data.subMenuItems);
  }

  public onOverlayClick(): void {
    this._asideService.updateAsideMenuVisible(false);
  }

  public onBookNowClick(): void {
    this._asideService.updateAsideMenuVisible(!this.isAsideMenuVisible);
  }

  private _watchMainNavBarCollapse$(): Observable<boolean> {
    return this._qimaResponsiveService.isMainNavBarCollapsed$().pipe(
      tap({
        next: (isMatched): void => {
          if (this.asideItemCheckedIndex !== 0) {
            this._asideService.updateIsAsideCollapsed(isMatched);
          }
        },
      })
    );
  }

  private _watch(): void {
    this._asideService.asideMenuVisible$.pipe(untilDestroyed(this)).subscribe((isVisible: boolean): void => {
      this.isAsideMenuVisible = isVisible;
    });

    this._asideService.asideItemCheckedIndex$.pipe(untilDestroyed(this)).subscribe((index: number): void => {
      this.asideItemCheckedIndex = index;
      this._changeDetectorRef.markForCheck();
    });

    this._asideService.isAsideCollapsed$.pipe(untilDestroyed(this)).subscribe((isAsideCollapsed: boolean): void => {
      this.isAsideCollapsed = isAsideCollapsed;
      this._changeDetectorRef.markForCheck();
    });

    this._asideService.collapseMenuClick$.pipe(untilDestroyed(this)).subscribe((): void => {
      this.isAsideCollapsed = !this.isAsideCollapsed;
      this._asideService.updateIsAsideCollapsed(this.isAsideCollapsed);
      this._changeDetectorRef.markForCheck();
    });

    this._router.events.pipe(untilDestroyed(this)).subscribe((event): void => {
      if (event instanceof NavigationEnd) {
        this._updateAsideItemCheckedIndex(event.url);
        this.isSystemSwitchVisible = !this._appUserSettingService.getIsQimaProduce() && !this._appUserSettingService.getIsLifeScience();
        this._changeDetectorRef.markForCheck();
      }
    });

    this._watchMainNavBarCollapse$().pipe(untilDestroyed(this)).subscribe();
    this._appAuthenticationService.currentUserSubject$.pipe(untilDestroyed(this)).subscribe((): void => {
      this._initData();
    });

    this._qimaResponsiveService
      .isMobile$()
      .pipe(untilDestroyed(this))
      .subscribe((isMobile: boolean): void => {
        this.isMobile = isMobile;
        this._asideService.updateIsAsideCollapsed(!isMobile);
        this.isBookNowVisible = false;
        this.isSystemSwitchVisible = false;
        this.isAsideMoreVisible = false;

        if (isMobile) {
          const hasBookNow = this.menuItems.some((item): boolean => item.id === 'bookNow');

          if (!hasBookNow && !this._appUserSettingService.getIsQimaProduce() && !this._appUserSettingService.getIsLifeScience()) {
            this.menuItems.splice(2, 0, {
              id: 'bookNow',
              label: 'APP_ASIDE.BOOK_NOW',
              iconName: EQimaIconName.INSPECT,
              matchPath: '',
              path: '',
              tooltip: '',
              visible: true,
              class: 'book-now',
            });
            this._updateAsideItemCheckedIndex(this._window.location.pathname);
          }

          this.tooltipPosition = EQimaPosition.TOP;
          this._updateMoreMenuItem();
          this._replaceToProduceReports();
        } else {
          this.tooltipPosition = EQimaPosition.RIGHT;
          this._setMenuItems();
          this._getIsBookNowVisible();
          this.isSystemSwitchVisible = !this._appUserSettingService.getIsQimaProduce() && !this._appUserSettingService.getIsLifeScience();
        }

        this._changeDetectorRef.markForCheck();
      });
  }

  private _initData(): void {
    this._getIsBookNowVisible();
    this._setMenuItems();
    this._updateAsideItemCheckedIndex(this._router.routerState.snapshot.url);
    this._getDynamicBookingMenus();
    this._changeDetectorRef.markForCheck();
  }

  private _getIsBookNowVisible(): void {
    this.isBookNowVisible =
      this._appUserSettingService.getIsBookNowVisible() &&
      !(this._isSuperMaster && this._appUserSettingService.getAccessDashboardOnly()) &&
      !this._appUserSettingService.getIsQimaProduce() &&
      !this._appUserSettingService.getIsLifeScience();
  }

  private _setMenuItems(): void {
    if (this._appUserSettingService.getIsLifeScience()) {
      this._setLifeScienceMenuItems();

      return;
    }

    if (this._appUserSettingService.getIsQimaProduce()) {
      this._setProducedMenuItems();

      return;
    }

    this.menuItems = MENU_ITEMS_COMMON.map((item: IAsideItem): IAsideItem => {
      item.visible = this._getAsideMenuVisible(item.id);

      return item;
    });

    this._setUnitsSubItems();
  }

  private _setUnitsSubItems(): void {
    const unitsItem = this.menuItems.find((item): boolean => item.id === 'units');

    if (unitsItem) {
      const foodCertificationSubItems = [
        {
          id: 'producers',
          label: 'APP_ASIDE.COMMON_ITEMS.PRODUCERS',
          iconName: EQimaIconName.VENDOR,
          matchPath: '/producers',
          path: '/producers',
          tooltip: 'APP_ASIDE.COMMON_ITEMS.PRODUCERS',
        },
        {
          id: 'fields',
          label: 'APP_ASIDE.COMMON_ITEMS.FIELDS',
          iconName: EQimaIconName.FARM,
          matchPath: '/fields',
          path: '/fields',
          tooltip: 'APP_ASIDE.COMMON_ITEMS.FIELDS',
        },
        {
          id: 'facilities',
          label: 'APP_ASIDE.COMMON_ITEMS.FACILITIES',
          iconName: EQimaIconName.SUPPLIER_FACTORY,
          matchPath: '/facilities',
          path: '/facilities',
          tooltip: 'APP_ASIDE.COMMON_ITEMS.FACILITIES',
        },
        {
          id: 'shippers',
          label: 'APP_ASIDE.COMMON_ITEMS.SHIPPERS',
          iconName: EQimaIconName.SHIPPER,
          matchPath: '/shippers',
          path: '/shippers',
          tooltip: 'APP_ASIDE.COMMON_ITEMS.SHIPPERS',
        },
      ];
      const useNetworkSubItems = [
        {
          id: 'suppliers',
          label: 'APP_ASIDE.COMMON_ITEMS.SUPPLIERS',
          iconName: EQimaIconName.DISTRIBUTION_CENTER,
          matchPath: '/suppliers',
          path: '/suppliers',
          tooltip: 'APP_ASIDE.COMMON_ITEMS.SUPPLIERS',
        },
      ];

      if (this._appUserSettingService.getEnableFoodCertification()) {
        unitsItem.subItems = [...foodCertificationSubItems];
      }

      if (this._appUserSettingService.getEnableUseNetwork()) {
        unitsItem.subItems = [...useNetworkSubItems];
      }

      if (this._appUserSettingService.getEnableFoodCertification() && this._appUserSettingService.getEnableUseNetwork()) {
        unitsItem.subItems = [...foodCertificationSubItems, ...useNetworkSubItems];
      }
    }

    if (this._appUserSettingService.getEnableUseNetwork()) {
      if (unitsItem) {
        unitsItem.subItems = [
          {
            id: 'suppliers',
            label: 'APP_ASIDE.COMMON_ITEMS.SUPPLIERS',
            iconName: EQimaIconName.DISTRIBUTION_CENTER,
            matchPath: '/suppliers',
            path: '/suppliers',
            tooltip: 'APP_ASIDE.COMMON_ITEMS.SUPPLIERS',
          },
        ];
      }
    }

    if (this._appUserSettingService.getEnableFoodCertification() && this._appUserSettingService.getEnableUseNetwork()) {
      if (unitsItem) {
        unitsItem.subItems = [
          {
            id: 'suppliers',
            label: 'APP_ASIDE.COMMON_ITEMS.SUPPLIERS',
            iconName: EQimaIconName.DISTRIBUTION_CENTER,
            matchPath: '/suppliers',
            path: '/suppliers',
            tooltip: 'APP_ASIDE.COMMON_ITEMS.SUPPLIERS',
          },
          {
            id: 'producers',
            label: 'APP_ASIDE.COMMON_ITEMS.PRODUCERS',
            iconName: EQimaIconName.VENDOR,
            matchPath: '/producers',
            path: '/producers',
            tooltip: 'APP_ASIDE.COMMON_ITEMS.PRODUCERS',
          },
          {
            id: 'fields',
            label: 'APP_ASIDE.COMMON_ITEMS.FIELDS',
            iconName: EQimaIconName.FARM,
            matchPath: '/fields',
            path: '/fields',
            tooltip: 'APP_ASIDE.COMMON_ITEMS.FIELDS',
          },
          {
            id: 'facilities',
            label: 'APP_ASIDE.COMMON_ITEMS.FACILITIES',
            iconName: EQimaIconName.SUPPLIER_FACTORY,
            matchPath: '/facilities',
            path: '/facilities',
            tooltip: 'APP_ASIDE.COMMON_ITEMS.FACILITIES',
          },
          {
            id: 'shippers',
            label: 'APP_ASIDE.COMMON_ITEMS.SHIPPERS',
            iconName: EQimaIconName.SHIPPER,
            matchPath: '/shippers',
            path: '/shippers',
            tooltip: 'APP_ASIDE.COMMON_ITEMS.SHIPPERS',
          },
        ];
      }
    }
  }

  private _setProducedMenuItems(): void {
    // icon should be arranged in the following order
    const icons: EQimaIconName[] = [EQimaIconName.ANALYTICS, EQimaIconName.TICK_CIRCLE, EQimaIconName.MAGNIFIER, EQimaIconName.REPORTS, EQimaIconName.DATA_EXPORT];

    this._analyticsHttpService
      .getAnalyticTabs$()
      .pipe(untilDestroyed(this))
      .subscribe((analyticTabs: IAnalyticTab[]): void => {
        this.menuItems = [];

        analyticTabs.forEach((tab: IAnalyticTab, index: number): void => {
          const id = tab.tabKey;

          this.menuItems.push({
            id,
            label: tab.tabName,
            iconName: icons[index] || EQimaIconName.REPORTS,
            matchPath: `/analytics/${id}`,
            path: `/analytics/${id}`,
            tooltip: tab.tabName,
            visible: true,
          });
          this._updateMoreMenuItem();
          this._replaceToProduceReports();
          this._updateAsideItemCheckedIndex(this._window.location.pathname);
          this._changeDetectorRef.markForCheck();
        });
      });
  }

  private _setLifeScienceMenuItems(): void {
    this.menuItems = MENU_ITEMS_LIFE_SCIENCE;

    this._changeDetectorRef.markForCheck();
  }

  private _updateAsideItemCheckedIndex(url: string): void {
    if (!url) {
      return;
    }

    this.asideItemCheckedIndex = -1;

    this.menuItems.forEach((item: IAsideItem, index: number): void => {
      if (item.matchPath) {
        const isMyQimaPathMatch = url.startsWith(item.matchPath);
        const isMixedPathMatch = url.startsWith(`/aca${item.matchPath}`);
        const isDashboardPathMatch = url.startsWith(`/aca/dashboard`) && item.matchPath.startsWith('/analytics');

        if (isMyQimaPathMatch || isMixedPathMatch || isDashboardPathMatch) {
          this.asideItemCheckedIndex = index;
        }
      }
    });

    this._changeDetectorRef.markForCheck();
  }

  private _getAsideMenuVisible(id: string): boolean {
    let isVisible: boolean = true;

    if (this._isSuperMaster && this._appUserSettingService.getAccessDashboardOnly()) {
      return false;
    }

    // hide menus except reports for clients if isViewReportsOnly setting enabled
    if (this._appUserSettingService.getIsViewReportsOnly() && id !== 'reports') {
      return false;
    }

    switch (id) {
      case 'checklists':
        isVisible = !this._appUserSettingService.getEnableFoodCertification() && !this._appUserSettingService.getEnableUseNetwork();

        break;
      case 'payments':
        isVisible = !this._isSuperMaster && !this._appUserSettingService.getEnableFoodCertification() && !this._appUserSettingService.getEnableUseNetwork();

        break;
      case 'suppliers':
        isVisible = (!this._isSuperMaster || this._appUserSettingService.getEnableSupplierTab()) && !this._appUserSettingService.getEnableFoodCertification();

        break;
      case 'reports':
        isVisible = this._appUserSettingService.getEnableReportsPage();

        break;
      case 'network':
        isVisible = this._appUserSettingService.getEnableUseNetwork();

        break;
      case 'supplierDocuments':
        isVisible = this._appUserSettingService.getEnableUseNetwork();

        break;
      case 'units':
        isVisible = this._appUserSettingService.getEnableFoodCertification() || this._appUserSettingService.getEnableUseNetwork();

        break;
      case 'analytics':
        isVisible = !this._appUserSettingService.getEnableFoodCertification() && !this._appUserSettingService.getEnableUseNetwork();

        break;
    }

    return isVisible;
  }

  private _getDynamicBookingMenus(): void {
    this._asideHttpService
      .getDynamicBookingMenus$()
      .pipe(untilDestroyed(this))
      .subscribe((menus): void => {
        this.dynamicBookingMenus = menus;
        this._storageService.setItem(EStorageKeys.DYNAMIC_BOOKING_MENUS, JSON.stringify(this.dynamicBookingMenus));
        this._asideHttpService.dynamicBookingMenusSubject$.next();
        this._changeDetectorRef.markForCheck();
      });
  }

  private _checkReportsLabel(): void {
    if (this._appUserSettingService.getIsQimaProduce() || this._appUserSettingService.getIsLifeScience()) {
      return;
    }

    const reportsIndex = this.menuItems.findIndex((x): boolean => x.id === 'reports');

    if (this._appUserSettingService.getEnableFoodCertification()) {
      this.menuItems[reportsIndex].label = 'APP_ASIDE.COMMON_ITEMS.REPORTS_DOCUMENTS';
      this.menuItems[reportsIndex].tooltip = 'APP_ASIDE.COMMON_ITEMS.REPORTS_DOCUMENTS';
    }
  }

  private _updateMoreMenuItem(): void {
    if (!this.isMobile) {
      return;
    }

    const hasMoreMenu = this.menuItems.some((item): boolean => item.id === 'moreMenu');

    if (!hasMoreMenu && this.menuItems.length > 5) {
      this.menuItems.splice(4, 0, {
        id: 'moreMenu',
        label: 'COMMON.MORE',
        iconName: EQimaIconName.MENU,
        matchPath: '',
        path: '',
        tooltip: '',
        visible: true,
      });
    }
  }

  private _replaceToProduceReports(): void {
    const index = this.menuItems.findIndex((item): boolean => item.label === 'Report details');

    if (index !== -1) {
      this.menuItems[index] = Object.assign(this.menuItems[index], {
        label: 'REPORTS.TITLE',
        iconName: EQimaIconName.REPORTS,
        matchPath: '/produce-reports',
        path: '/produce-reports',
        tooltip: 'REPORTS.TITLE',
      });
    }
  }
}
